import React, { useEffect } from "react";

export default function Error() {

  if (typeof window === "undefined") return null;

  useEffect(() => {

    if(location.pathname.includes('/navigate-beyond-21')){
      window.location = '/navigate-beyond'
    }

  }, []);


  return (
    <h1>Error page</h1>
  )
}